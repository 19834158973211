
import { defineComponent, reactive, toRefs, ref, onMounted } from "vue";
import { Api } from "@/assets/Api";
import store from "@/store";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "RegisterRegister",
  components: {},
  setup() {
    let data = reactive<any>({
      legends: [
        { type: "success", label: "上午" },
        { type: "danger", label: "下午" },
        { type: "warning", label: "晚上" },
        { type: "", label: "全天" },
      ],
      City: [],
      HospitalInfo: {
        More: {
          nbzl: "",
        },
        RulesMSG: "",
        StopTreatment: [],
      },
      HospitalTableData: [],
      HospitalSearch: "",
      DepartmentTableData: [],
      DepartmentSearch: "",
      DoctorTableData: [],
      DoctorSearch: "",
      params: {
        City: "",
        HospitalId: "",
        HospitalName: "",
        Docking: false,
        DepId: "",
        DepName: "",
        DocId: "",
        DocName: "",
        PatientIdCard: "",
        PatientName: "",
        PatientMobile: "",
        SchemeDaypart: "",
        SchemeId: "",
        ResDate: "",
        NumId: "",
        lockcode: "",
        ResTime: "",
        ResNumber: "",
        PatientMediCardNbr: "",
        Addr: "",
        Guardian: "",
        GuardianIdCard: "",
        remark: "",
        UserIndex: "",
      },
      calendar: [],
      calendarRow: 0,
      Num: [],
      lockcode: "",
      userOptions: [],
      user: [],
      loading: false,
    });

    const SetUser = () => {
      data.params.PatientIdCard = data.user[data.params.UserIndex].sfz;
      data.params.PatientName = data.user[data.params.UserIndex].username;
      data.params.PatientMobile = data.user[data.params.UserIndex].tel;
    };

    const SearchUser = (queryString: any) => {
      if (queryString.length > 4) {
        data.loading = true;
        setTimeout(() => {
          Api.Api2.PlanOrderUser(queryString).then((res: any) => {
            console.info(res);
            data.loading = false;
            data.userOptions = [];
            data.UserIndex = "";
            data.user = res.data;
            if (res.code == "0") {
              for (let index = 0; index < res.data.length; index++) {
                var element = {
                  value: index,
                  label:
                    res.data[index].tel +
                    res.data[index].username +
                    res.data[index].sfz,
                };
                data.userOptions.push(element);
              }
            }
          });
        }, 200);
      } else {
        data.userOptions = [];
      }
    };

    const GetTagType = (SchemeDaypart: any) => {
      switch (SchemeDaypart) {
        case "0":
          return "success";
        case "1":
          return "danger";
        case "2":
          return "warning";
        default:
          return "";
      }
    };

    const GetBadgeType = (flag: any) => {
      switch (flag) {
        case "约满":
          return "warning";
        case "停诊":
          return "danger";
        default:
          return "primary";
      }
    };

    const GetHospitalList = () => {
      console.info(data.params.City);
      Api.Api2.HospitalList(data.params.City[1]).then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          data.HospitalTableData = res.data.Items.filter(
            (item: any) => item.Enabled == true
          );
          data.DepartmentTableData = [];
          data.DoctorTableData = [];
          data.calendar = [];
          data.calendarRow = 0;
          Clear();
        }
      });
    };

    const Clear = () => {
      if (data.lockcode != "") {
        var req = {
          NumId: data.NumId,
          HospitalId: data.params.HospitalId,
          LockCode: data.lockcode,
        };
        Api.Api2.PlanNumberUnlock(req).then((res: any) => {
          console.info(res);
          if (res.code == "0") {
            data.lockcode = "";
          }
        });
      }
      data.params.ResDate = "";
      data.params.NumId = "";
      data.params.ResTime = "";
      data.params.ResNumber = "";
      data.params.SchemeDaypart = "";
      data.params.SchemeId = "";
      data.params.lockcode = "";
    };

    const PlanAddOrder = () => {
      if (data.params.NumId == "") {
        Api.Msg.warning("请选择号段");
        return;
      }
      if (data.params.PatientIdCard == "") {
        Api.Msg.warning("请输入身份证");
        return;
      }

      if (data.params.PatientName == "") {
        Api.Msg.warning("请输入姓名");
        return;
      }
      if (data.params.PatientMobile == "") {
        Api.Msg.warning("请输入手机号码");
        return;
      }
      console.info(data.params);
      Api.Api2.PlanAddOrder(data.params).then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          data.lockcode = "";
          Clear();
          Api.Msg.successAlwaysShow(res.data);
        } else {
          Api.Msg.warning(res.data);
        }
      });
    };

    const SetNumId = (NumId: any) => {
      var findVal = data.Num.find((item: any) => {
        return item.NumId == NumId;
      });
      data.params.ResTime = findVal.ResTime;
      data.params.ResNumber = findVal.ResNumber;
      if (data.lockcode != "") {
        var req = {
          NumId: data.params.NumId,
          HospitalId: data.params.HospitalId,
          LockCode: data.lockcode,
        };
        Api.Api2.PlanNumberUnlock(req).then((res: any) => {
          console.info(res);
          if (res.code == "0") {
            data.lockcode = "";
          }
        });
      }
      if (!data.params.Docking) {
        Api.Api2.PlanNumberLock(data.params).then((res: any) => {
          console.info(res);
          if (res.code == "0") {
            data.lockcode = res.data;
            data.params.lockcode = res.data;
          }
        });
      }
    };

    const setForm = (row: any, SchemeDaypart: any) => {
      console.info(row);
      data.params.DocId = SchemeDaypart.DocId;
      data.params.DocName = SchemeDaypart.DocName;
      data.params.SchemeDaypart = SchemeDaypart.SchemeDaypart;
      data.params.SchemeId = SchemeDaypart.SchemeId;
      data.params.ResDate = row.date;
      data.params.NumId = "";
      data.params.ResTime = "";
      data.params.ResNumber = "";

      Api.Api2.PlanNumber(data.params).then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          data.Num = res.data;
        } else {
          data.Num = [];
        }
      });
    };

    const calendarList = (i: any) => {
      console.info(i);
      var list = [];
      for (let index = 0; index < data.calendar.length; index++) {
        if (index >= i * 7 && index < (i + 1) * 7)
          list.push(data.calendar[index]);
      }
      console.info(list);
      return list;
    };

    const getFormatTimes = (day: any) => {
      var myDate = new Date();
      myDate.setDate(myDate.getDate() + day);
      var wk = myDate.getDay();
      var yy = String(myDate.getFullYear());
      var mm =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1;
      var dd = String(
        myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate()
      );
      var nowDate = yy + "-" + mm + "-" + dd;
      return {
        wk: wk,
        date: nowDate,
      };
    };

    const PlanScheme = (row: any) => {
      data.params.DocId = row.DocId;
      data.params.DocName = row.DocName;
      data.calendar = [];
      var add = 0;
      var wk = getFormatTimes(add).wk;
      if (wk != 0) {
        for (let index = -1 * wk + 1; index <= 0; index++) {
          data.calendar.push({
            date: getFormatTimes(index).date,
          });
        }
      }
      Api.Api2.PlanScheme(data.params).then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          if (res.data.length > 0) {
            var tdate = new Date();
            tdate.setDate(tdate.getDate() + 1);
            var sp = res.data[res.data.length - 1].SchemeDate.split("-");
            var odate = new Date();
            odate.setFullYear(sp[0], sp[1] - 1, sp[2]);
            while (tdate <= odate) {
              add++;
              data.calendar.push({
                date: getFormatTimes(add).date,
              });
              tdate.setDate(tdate.getDate() + 1);
            }
            for (let index = 0; index < res.data.length; index++) {
              var findVal = data.calendar.find((item: any) => {
                return item.date === res.data[index].SchemeDate;
              });

              if (findVal.SchemeDaypart)
                findVal.SchemeDaypart.push({
                  SchemeDaypart: res.data[index].SchemeDaypart,
                  Flag: res.data[index].Flag,
                });
              else
                findVal.SchemeDaypart = [
                  {
                    SchemeDaypart: res.data[index].SchemeDaypart,
                    Flag: res.data[index].Flag,
                  },
                ];
            }

            odate.setDate(odate.getDate() + 10);
            while (tdate <= odate) {
              add++;
              data.calendar.push({
                date: getFormatTimes(add).date,
                button: true,
              });
              tdate.setDate(tdate.getDate() + 1);
            }
          }
        } else {
          data.calendar = [];
        }
        data.calendarRow = Math.ceil(data.calendar.length / 7);
        console.info(data.calendar);
        console.info(data.calendarRow);
      });
    };

    const getHospitalName = (data: any) => {
      return (
        data.HospitalName +
        (data.Docking ? " -- 直连" : "") +
        (data.isguahao ? "" : " -- 不提供预约")
      );
    };
    const getDoctorName = (data: any) => {
      return data.DocName + (data.DocTitle == "" ? "" : " -- " + data.DocTitle);
    };
    const PlanHospital = () => {
      Api.Api2.PlanHospital().then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          data.HospitalTableData = res.data;
          data.DepartmentTableData = [];
          data.DoctorTableData = [];
          data.calendar = [];
          data.calendarRow = 0;
          Clear();
        }
      });
    };
    const GetDepartmentList = (row: any) => {
      data.params.HospitalId = row.HospitalId;
      data.params.HospitalName = row.HospitalName;
      data.params.Docking = row.Docking;
      data.DepartmentTableData = [];
      Api.Api2.PlanDepartment(data.params).then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          data.DepartmentTableData = res.data;
          data.DoctorTableData = [];
          data.calendar = [];
          data.calendarRow = 0;
          Clear();
        }
      });
      Api.Api2.HospitalMore(row.HospitalId).then((res: any) => {
        data.HospitalInfo = {};
        if (res.code == "0") {
          data.HospitalInfo = res.data;
        }
      });
    };
    const GetDepartmentScheme = (row: any) => {
      data.params.DepId = row.DepId;
      data.params.DepName = row.DepName;
      data.calendar = [];
      var add = 0;
      var wk = getFormatTimes(add).wk;
      if (wk != 0) {
        for (let index = -1 * wk + 1; index <= 0; index++) {
          data.calendar.push({
            date: getFormatTimes(index).date,
          });
        }
      }
      Api.Api2.PlanDepartmentScheme(data.params).then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          if (res.data.length > 0) {
            var tdate = new Date();
            tdate.setDate(tdate.getDate() + 1);
            var sp = res.data[res.data.length - 1].SchemeDate.split("-");
            var odate = new Date();
            odate.setFullYear(sp[0], sp[1] - 1, sp[2]);
            while (tdate <= odate) {
              add++;
              data.calendar.push({
                date: getFormatTimes(add).date,
              });
              tdate.setDate(tdate.getDate() + 1);
            }

            for (let index = 0; index < res.data.length; index++) {
              data.calendar
                .filter((item: any) => item.date == res.data[index].SchemeDate)
                .forEach((element: any) => {
                  if (element.SchemeDaypart)
                    element.SchemeDaypart.push({
                      SchemeDaypart: res.data[index].SchemeDaypart,
                      Flag: res.data[index].Flag,
                      DocExcel: res.data[index].DocExcel,
                      DocDisc: res.data[index].DocDisc,
                      DocId: res.data[index].DocId,
                      DocName: res.data[index].DocName,
                      DocSex: res.data[index].DocSex,
                      DocTitle: res.data[index].DocTitle,
                      Fee: res.data[index].Fee,
                      ImageUrl: res.data[index].ImageUrl,
                      SchemeId: res.data[index].SchemeId,
                      SchemeType: res.data[index].SchemeType,
                      SchemeWeekday: res.data[index].SchemeWeekday,
                    });
                  else
                    element.SchemeDaypart = [
                      {
                        SchemeDaypart: res.data[index].SchemeDaypart,
                        Flag: res.data[index].Flag,
                        DocExcel: res.data[index].DocExcel,
                        DocDisc: res.data[index].DocDisc,
                        DocId: res.data[index].DocId,
                        DocName: res.data[index].DocName,
                        DocSex: res.data[index].DocSex,
                        DocTitle: res.data[index].DocTitle,
                        Fee: res.data[index].Fee,
                        ImageUrl: res.data[index].ImageUrl,
                        SchemeId: res.data[index].SchemeId,
                        SchemeType: res.data[index].SchemeType,
                        SchemeWeekday: res.data[index].SchemeWeekday,
                      },
                    ];
                });
            }
          }
        } else {
          data.calendar = [];
        }
        data.calendarRow = Math.ceil(data.calendar.length / 7);
        console.info(data.calendar);
        console.info(data.calendarRow);
      });
    };
    onMounted(() => {
      const allHospital: string = store.getters.allHospital;
      if (allHospital) {
        const jsonHospital = JSON.parse(allHospital);
        jsonHospital.forEach((province: any) => {
          province.children.forEach((city: any) => {
            city.value += "";
            delete city.children;
          });
        });
        data.City = jsonHospital;
      }
    });

    return {
      ...toRefs(data),
      SetUser,
      SearchUser,
      GetTagType,
      GetBadgeType,
      GetHospitalList,
      Clear,
      PlanAddOrder,
      SetNumId,
      setForm,
      calendarList,
      getFormatTimes,
      PlanScheme,
      getHospitalName,
      getDoctorName,
      PlanHospital,
      GetDepartmentList,
      GetDepartmentScheme,
    };
  },
});
